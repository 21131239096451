.animate-dot {
    transition: fill 1s ease;
    animation: colorChange 0.5s infinite;
    position: relative;
}



@keyframes colorChange {
    0% {
        fill: #717171;
        /* Initial color */
    }

    100% {
        fill: #CE0E2D;
        /* Final color */
    }
}

@keyframes rippleEffect {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}